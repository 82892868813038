import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import { icons } from "react-icons";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import VisibilitySensor from "react-visibility-sensor";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        icons: (
          <a
            href="https://accounts.google.com/InteractiveLogin/signinchooser?continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&osid=1&passive=1209600&service=mail&ifkv=AdF4I76c0ae876wNslhl2qZzVULGpvcoWFEubUXANAOQ3bZO-qhj6TjXwMJ_Ls88utBpR5pnU_JVVg&ddm=0&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
            target="_blank"
          >
            <FiMail />
          </a>
        ),
        name: "Email",
        countTitle:
          "For any inquiries, please fill out the contact form below.",
        detail: "Support@dinosysinfotech.com",
      },
      {
        icons: (
          <a href="">
            <FiPhone />
          </a>
        ),
        name: "Phone",
        countTitle: "Give us a call to speak with a representative.",
        detail: "+91 XXXXXXXX",
      },
      {
        icons: (
          <a
            href="https://www.google.com/maps/@19.4804642,72.9224318,14z?entry=ttu"
            target="_blank"
          >
            <FiMapPin />
          </a>
        ),
        name: "Office",
        countTitle: "Visit our office during business hours.",
        detail: "XXXXXX",
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12 service service__style--2"
              key={index}
            >
              <span className="icon">{value.icons}</span>
              <div className="content">
                <h4 className="font-weight-bold title">{value.name}</h4>

                <p className="description">{value.countTitle}</p>
                <p className="description">{value.detail}</p>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
