import React from "react";
import HeaderFive from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronsUp } from "react-icons/fi";
import CounterOne from "./counters/CounterOne";

function Contact() {
  return (
    <React.Fragment>
      {/* <style>
        {`
          @media (min-width: 768px) and (max-width: 991.98px) {
            .mt-md-200 {
              margin-top: 200px;
            }
            .mb-md-200 {
              margin-bottom: 200px;
            }
          }
        `}
      </style> */}

      <HeaderFive
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area pt--50 pb--155 bg_color--5 mt-md-200 mb-md-200">
        <div className="container">
          <CounterOne />
        
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronsUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
        <FooterTwo />
      </div>
    </React.Fragment>
  );
}

export default Contact;
