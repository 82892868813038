import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/HeaderFive";
import BlogContent from "./blog/BlogContent";
import ServiceFour from "./service/ServiceFour";
import AboutFive from "../component/HomeLayout/homeOne/AboutFive";
import AboutFour from "../component/HomeLayout/homeOne/AboutFour";
import FooterTwo from "../component/footer/FooterTwo";

class Service extends Component {
  render() {
    const PostList = BlogContent.slice(0, 3);
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Service" />

        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Transforming Globally */}
        <div className="rn-about-area pt--100 pb--100 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 section-title">
                <h3 className="title">Transforming Globally</h3>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="accordion-wrapper mt--30">
                    <p>
                      Welcome to Dinosys Infotech Pvt. Ltd., where we offer a
                      comprehensive range of services to help LEA’s thrive in
                      the digital age. From cutting-edge IT solutions to expert
                      training and consultation, we have the expertise and
                      experience to meet all your technology needs.
                    </p>
                  </div>
                  {/* <div className="about-button mt--50">
                    <a className="rn-btn btn-solid" href="">
                      Learn More
                    </a>
                    <a className="rn-btn ml-4" href="">
                      Sign Up
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Transforming Globally */}

        {/* Start {Discover our Comprehensive IT Services} */}
        <div className="rn-blog-area pt--120 bg_color--5 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="section-title text-left">
                  <h3>Discover our Comprehensive IT Services</h3>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a>
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a>{value.title}</a>
                      </h4>
                      <p className="blogtype">{value.category}</p>
                      <div className="blog-btn"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End{Discover our Comprehensive IT Services} */}

        {/* Start {ServiceFour} */}
        <div className="service-area pt--130 pb--30  bg_color--5">
          <div className="container">
            <ServiceFour />
          </div>
        </div>
        {/* End {ServiceFour} */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5">
          <AboutFour />
        </div>
        {/* End About Area */}

        {/* Start About Area */}
        <div className="about-area ptb--60 bg_color--5">
          <AboutFive />
        </div>
        {/* End About Area */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="  mt--5">
                <div className="section-title">
                  <h3 className="title">
                    Transform Your Business with <br />
                    Innovative Solutions
                  </h3>
                  <p>
                    Discover how our IT services can drive your business forward
                    with cutting-edge technology.
                  </p>
                  <div className="about-button mt--30">
                    <a className="rn-btn btn-solid" href="/contact">
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Area  */}
        <FooterTwo />
        {/* End Footer Area  */}
      </React.Fragment>
    );
  }
}
export default Service;
