import React, { Component } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiBox,
  FiChevronRight,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiBox />,
    title: "Consultation and Planning",
    description: "Discuss your IT requirements and goals with our expert team.",
  },
  {
    icon: <FiBox />,
    title: "Development and Implementation",
    description:
      "Our skilled professionals will design and deploy customized IT solutions.",
  },
  {
    icon: <FiBox />,
    title: "Testing and Quality Assurance",
    description:
      "We rigorously test and ensure the reliability of our IT solutions.",
  },
  // {
  //     icon: <FiMonitor />,
  //     title: 'Mobile App Development',
  //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  // },
  // {
  //     icon: <FiUsers />,
  //     title: 'Marketing & Reporting',
  //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  // },
  // {
  //     icon: <FiMonitor />,
  //     title: 'Mobile App Development',
  //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
  // }
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div className="service service__style--2">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
