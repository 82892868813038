import React from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import AboutSix from "../component/HomeLayout/homeOne/AboutSix";
import AboutSeven from "../component/HomeLayout/homeOne/AboutSeven";
import FooterTwo from "../component/footer/FooterTwo";
import AboutNine from "../component/HomeLayout/homeOne/AboutNine";
import AboutTen from "../component/HomeLayout/homeOne/AboutTen";

const PricingTable = () => {
  return (
    <>
      <PageHelmet pageTitle="Pricing Table" />

      {/* Start Header Area  */}
      <Header
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      {/* End Header Area  */}

      {/* start of service and product */}
      <div className="rn-about-area pt--100 pb--120 bg_color--5">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 section-title">
              <h3 className="title">Transforming IT Solutions</h3>
            </div>
            <div className="col-lg-6">
              <div className="about-inner inner">
                <div className="accordion-wrapper mt--20">
                  <p>
                    Welcome to Dinosys Infotech Pvt. Ltd, your one-stop
                    destination for comprehensive IT services. With our
                    expertise and dedication, we provide top-notch solutions to
                    meet all your technology needs. Whether you require software
                    development, cybersecurity, or IT consulting, we have you
                    covered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  end of service and product*/}

      {/*Start of about6  */}
      <div className="about-area ptb--120 bg_color--5">
        <AboutSix />
      </div>
      {/*End of about6  */}

      {/*Start of about7  */}
      <div className="about-area ptb--120 bg_color--5">
        <AboutSeven />
      </div>
      {/*End of about7  */}

       {/*Start of about7  */}
       <div className="about-area ptb--120 bg_color--5">
        <AboutNine />
      </div>
      {/*End of about7  */}

       {/*Start of about7  */}
       <div className="about-area ptb--120 bg_color--5">
        <AboutTen />
      </div>
      {/*End of about7  */}

      {/* Start of last component of service  */}
      <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
        <div className="container">
          <div className="row ">
            {/* Start Single Service  */}
            <div className="col-lg-6 col-md-12 mt--5 ">
              <div className="section-title">
                <h3 className="title">Transform Your Business with IT</h3>
                <p>Unlock the Power of Technology for Your Success.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 pt_sm--10 d-flex justify-content-lg-end">
              <a className="rn-btn btn-solid" href="/contact">
                Contact
              </a>
            </div>
            {/* End Single Service  */}
          </div>
        </div>
      </div>
      {/* end of last component of service  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <FooterTwo />
      {/* End Footer Area  */}
    </>
  );
};
export default PricingTable;
