import React, { Component } from "react";
import { FiBox } from "react-icons/fi";

class TeamTwo extends Component {
  render() {
    let title =
        "Unlock the Secrets of Crime Investigation with Our Comprehensive Curriculum",
      description =
        "Our crime investigation training program offers a comprehensive curriculum that covers various aspects of crime investigation. From forensic science to criminal profiling, our courses are designed to provide you with the knowledge and skills needed to excel in this field.",
      icon = <FiBox />;
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/seven.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TeamTwo;
