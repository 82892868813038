import React, { Component } from "react";
import { FiChevronRight } from "react-icons/fi";

class AboutTwo extends Component {
  render() {
    let title =
        "Choose Dinosys for Reliable IT Solutions and Crime Investigation Services",
      description =
        "At Dinosys Infotech Pvt. Ltd, we offer a wide range of IT services and expert consultation for investigating crimes. Our team of professionals is dedicated to delivering top-notch solutions and ensuring the highest level of customer satisfaction.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h4 className="title">Experience</h4>
                        <p>
                          Benefit from our years of experience in the IT
                          industry and crime investigation field.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h4 className="title">Expertise</h4>
                        <p>
                          Our team of skilled professionals has the expertise to
                          handle complex IT projects and investigations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="service-btn mt-5">
                    <a className="btn-transparent rn-btn-dark " href="/contact">
                      <span className="text">
                        Contact Us <FiChevronRight />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/secondImg.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
