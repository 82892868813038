import React, { Component } from "react";

class AboutTen extends Component {
  render() {
    let title = "LawEnforce24",
      description =
        "One of the most time-consuming aspects of police work is the generation of reports and documentation. LawEnforce24 simplifies this process significantly, ensuring accurate records and efficient data management. With user-friendly interfaces and automated features, officers can quickly generate detailed reports, reducing the chances of errors and ensuring that all necessary information is recorded comprehensively.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container ">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/LawEnforce24.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTen;
