import React from "react";

import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiChevronRight } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import TeamTwo from "./TeamTwo";
import TeamThree from "./TeamThree";
import BlogContent from "../elements/blog/BlogContent";
import TeamFour from "./TeamFour";
import FooterTwo from "../component/footer/FooterTwo";

const PostList = BlogContent.slice(3, 6);
const Team = () => {
  return (
    <>
      <PageHelmet pageTitle="Team" />
      {/* Start Header Area  */}
      <Header
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      {/* End Header Area  */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Team Area  */}
        <div className="rn-team-wrapper pt--120 pb--40 bg_color--5">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2>Uncover the Truth</h2>
                    <p>
                      Gain the skills needed to investigate crimes and bring
                      justice to the victims.
                    </p>
               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start of TeamTwo */}
        <div className="about-area pt--40 pb--110 bg_color--5">
          <TeamTwo />
        </div>
        {/* End of TeamTwo */}

        {/* Start of TeamThree */}
        <div className="about-area pt--40 pb--110 bg_color--5">
          <TeamThree />
        </div>

        {/* End of TeamThree */}

        {/* Start {Discover our Comprehensive IT Services} */}
        <div className="rn-blog-area pt--100 pb--40 bg_color--5 mb-dec--30">
          <div className="container">
            <div className="row  align-items-center">
              <div className="col-lg-6 section-title">
                <h3 className="title">
                  Uncover the Secrets of Crime Investigation Training
                </h3>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="accordion-wrapper mt--30">
                    <p>
                      At Dinosys Infotech Pvt. Ltd, our Crime Investigation
                      Training program provides participants with a
                      comprehensive overview of the training process and equips
                      them with the necessary skills to excel in the field.
                      Through a combination of theoretical knowledge and
                      practical exercises, participants will learn the
                      intricacies of crime investigation, evidence collection,
                      and forensic analysis. Join us and embark on a journey to
                      become a skilled crime investigator.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a>
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a>{value.title}</a>
                      </h4>
                      <p className="blogtype">{value.category}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="about-button ">
              <a
                className="rn-btn btn-solid"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdzAP4xNGOjK1BA5_huKTZIkFGFur_YWvU5VKx0MMlQKQWQ4A/viewform"
                target="_blank"
              >
                Join
              </a>
              <a
                className="btn-transparent rn-btn-dark ml-4"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdzAP4xNGOjK1BA5_huKTZIkFGFur_YWvU5VKx0MMlQKQWQ4A/viewform"
                target="_blank"
              >
                <span className="text">
                  Enroll <FiChevronRight />
                </span>
              </a>
            </div>
          </div>
        </div>
        {/* End{Discover our Comprehensive IT Services} */}

        {/* Start Team Area  */}
        <div className="rn-team-wrapper ptb--150 bg_color--5">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12"></div>
              </div>
              <TeamFour column="col-lg-3" teamStyle="" item="4" />
            </div>
          </div>
        </div>
        {/* End Team Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <FooterTwo />
      {/* End Footer Area  */}
    </>
  );
};

export default Team;
