const BlogContent = [
  {
    images: "01",
    title: " Become an Expert in Crime Investigation",
    category:
      "We offer specialized training programs to equip you with the skills needed for crime investigation.",
    buttonName: "Learn More",
  },
  {
    images: "02",
    title: "Consultation Services for Effective Crime Prevention ",
    category:
      "Our team of experts provides consultation services to help organizations prevent and tackle crime effectively.",
    buttonName: "Sign Up",
  },

  {
    images: "03",
    title: "Enhance Your IT Infrastructure with Our Expertise",
    category:
      "We offer a range of IT services to help businesses optimize their technology infrastructure.",
    buttonName: "Get Started",
  },
  {
    images: "04",
    title: " Expert-Led Training Sessions",
    category:
      "Our training sessions are conducted by industry experts with years of experience in crime investigation and law enforcement.",
  },
  {
    images: "05",
    title: "Hands-On Practical Exercises",
    category:
      "Participants will engage in hands-on practical exercises to apply their knowledge and develop their investigative skills.",
  },
  {
    images: "06",
    title: "Cutting-Edge Forensic Techniques",
    category:
      "Learn the latest forensic techniques and tools used in crime investigation to stay ahead in the field.",
  },
  {
    images: "01",
    title: "Developed Softwares ",
    category:
      "Discover about the cutting-edge platforms developed at Dinosys Infotech Pvt.Ltd.",
    course: "Technology",
    buttonName: "Read more",
  },
  {
    images: "02",
    title: "Protect Your Data ",
    category:
      "Learn about the latest cybersecurity threats and how to stay safe.",
    course: "Cybersecurity",
    buttonName: "Read more",
  },

  {
    images: "03",
    title: "Building Modern Websites",
    category: "Discover the latest trends and techniques in web development.",
    course: "Web Development",
    buttonName: "Read more",
  },
];

export default BlogContent;
