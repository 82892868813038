import React, { Component } from "react";
import { FiBox, FiChevronRight } from "react-icons/fi";

class AboutFour extends Component {
  render() {
    let title =
        "Unlock the Secrets of Crime Investigation with our Comprehensive Training Program",
      description =
        "Our Crime Investigation Training program offers in-depth courses, practical training methods, and industry-recognized certifications to equip you with the skills needed to solve complex cases.",
      icon = <FiBox />;
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <div className="service service__style--2">
                      <span className="icon  ">{icon}</span>
                      <h3 className="title">{title}</h3>
                      <div className="content">
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/fourthImg.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutFour;
