import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import Header from "../component/header/HeaderFive";
import Helmet from "../component/common/Helmet";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import ServiceList from "./service/ServiceList";
import AboutEight from "../component/HomeLayout/homeOne/AboutEight";
import Testimonial from "./Testimonial";
import ScrollToTop from "react-scroll-up";
import FooterTwo from "../component/footer/FooterTwo";
import ServiceTwo from "./service/ServiceTwo";
import { FiChevronUp, FiChevronRight } from "react-icons/fi";

const SlideList = [
  {
    id: 1,
    textPosition: "text-right",
    bgImage: "bg_image--32",
  },
];

class CorporateBusiness extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Dinosys Infotech" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="rn-about-area  pb--50 bg_color--5">
          <div className="container">
            <Slider className="">
              {SlideList.map((value) => (
                <div
                  key={value.id}
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                ></div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area  */}
        <div className="rn-about-area pt--120 pb--50 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 section-title">
                <h3 className="title mt--30">
                  Transforming IT Solutions for a Digital World
                </h3>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="accordion-wrapper ">
                    <p>
                      Welcome to Dinosys Infotech Pvt. Ltd, your trusted partner
                      for IT services, training, and consultation. We specialize
                      in investigating and solving complex digital crimes,
                      providing innovative solutions to businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Services Area */}
        <div className="service-area pt--50  bg_color--5">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Services Area */}

        {/* Start About Area */}
        <div className="about-area ptb--100 bg_color--5">
          <AboutTwo />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div id="service" className="fix">
          <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h3 className="title">
                      Streamline Your IT Solutions with <br /> Dinosys Infotech
                    </h3>
                    <p>
                      At Dinosys Infotech, we offer a seamless process for
                      engaging with our IT services and solutions. From
                      consultation to implementation, we ensure a smooth
                      experience for our clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row creative-service">
                <div className="col-lg-12">
                  <ServiceList
                    item="6"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div className=" mt-5  ">
                      <a
                        className="btn-transparent rn-btn-dark "
                        href="/contact"
                      >
                        <span className="text">
                          Contact Us <FiChevronRight />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Discover Our */}
        <div className="service-area pt--50  bg_color--5">
          <div className="container">
            <AboutEight />
          </div>
        </div>
        {/* End Discover Our */}

        {/* Start Service Area  */}
        {/* <div id="service" className="fix">
          <div className="service-area creative-service-wrapper pt--50  bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0  mt_sm--30 mt--60">
                    <h3 className="title">What Our Clients Say</h3>
                    <p className="ml--30">
                      Read testimonials from our satisfied clients
                    </p>
                  </div>
                </div>
              </div> */}

              {/* Start Testimonial Area */}
              {/* <div className="mt_sm--30">
                <Testimonial />
              </div> */}
              {/* End Testimonial Area */}
            {/* </div>
          </div>
        </div> */}
        {/* End Service Area  */}

        {/* Start Featured Service Area  */}
        <div id="service" className="fix">
          <div className="service-area creative-service-wrapper pt--150 pb--120 bg_color--5 ">
            <div className="container">
              <div className=" ml_sm--40">
                {/* Start Single Service  */}
                <div className="mt--5">
                  <div className="section-title">
                    <h3 className="title">Transform Your Business with Us</h3>
                    <p>
                      Unlock the potential of your business with our expert IT
                      solutions and consulting services.
                    </p>
                    <div className="about-button mt--20">
                      <a className="rn-btn btn-solid" href="/contact">
                        Contact
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Single Service  */}
              </div>
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Area  */}
        <FooterTwo />
        {/* End Footer Area  */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
