import React, { Component } from "react";
import { FiBox } from "react-icons/fi";

class TeamThree extends Component {
  render() {
    let title = "Unlock Your Potential with Crime Investigation Training",
      description =
        "Enrolling in our crime investigation training program offers numerous benefits. Gain the skills and knowledge needed to excel in the field of crime investigation.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <div className="service service__style--2 ">
                          <div className="icon">
                            <FiBox />
                          </div>
                          <h4 className="title">Expert Instructors</h4>
                          <div className="content">
                            <p>
                              Learn from experienced professionals who have a
                              deep understanding of crime investigation
                              techniques.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <div className="service service__style--2 ">
                          <div className="icon">
                            <FiBox />
                          </div>
                          <h4 className="title">Hands-on Training</h4>
                          <div className="content">
                            <p>
                              Get practical experience through simulated crime
                              scenes and real-life case studies.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-btn mt-5">
                    <a
                      className="rn-btn "
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdzAP4xNGOjK1BA5_huKTZIkFGFur_YWvU5VKx0MMlQKQWQ4A/viewform"
                      target="_blank"
                    >
                      Enroll
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/six.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TeamThree;
