import React, { Component } from "react";

class AboutNine extends Component {
  render() {
    let title = "Trinetra",
      description =
        "Trinetra is an advanced OSINT (Open Source Intelligence) tool that aggregates and analyzes online data to provide a comprehensive profile of an individual. By simply inputting an email address or phone number, Trinetra can fetch a wide array of details, including profile photos, various associated names, UPI IDs, and profiles from social media platforms such as Facebook and LinkedIn.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container ">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/Trinetra.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutNine;
