import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
// import { Accordion, Card, Button, Container, Row, Col } from "react-bootstrap";

class VideoPopup extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <>
        <PageHelmet pageTitle="Video Popup" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Page Wrapper  */}
        <div className="page-wrapper">
          <div className="rn-brand-area pt--120 bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 ">
                    <h3 className="title">
                      10 Tips for Securing your Digital <br /> Data
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Wrapper  */}

          {/* Start Video Area  */}
          <div className="rn-section pb--120 pt--40 bg_color--5 ">
            <div className="container">
              <div className="row sercice-details-content align-items-center">
                <div className="col-lg-12">
                  <div className="thumb position-relative">
                    <img
                      className="w-100"
                      src="/assets/images/10-tips.jpg"
                      alt="Service Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Video Area  */}
        </div>
        {/* End Page Wrapper  */}

        {/* Start of Common */}
        {/* <div className="rn-about-area ptb--60 bg_color--5">
          <div className="rn-about-wrapper">
            <Container>
              <Row>
                <Col md={6} className="mb-4 section-title">
                  <h3 className="title ">Common</h3>
                  <p>
                    Find answers to frequently asked questions related to the
                    blog post topic.
                  </p>
                  <a className="rn-btn btn-solid" href="/contact">
                    Contact Us
                  </a>
                </Col>
                <Col md={6}>
                  <Accordion defaultActiveKey="0">
                    <Card className="bg_color--5">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="" eventKey="1">
                          What is the topic?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>Content for what is the topic?</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="bg_color--5">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="" eventKey="2">
                          How to access?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>Content for how to access?</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="bg_color--5">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="" eventKey="3">
                          Is it free?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>Content for is it free?</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="bg_color--5">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="" eventKey="4">
                          Can I share?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>Content for can I share?</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="bg_color--5">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="" eventKey="5">
                          Need more help?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>Content for need more help?</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
        {/* End of Common */}

        {/*Start of Stay Updated with our blog  */}
        {/* <div className="rn-featured-service-area pt--130 pb--120 bg_color--5">
          <div className="container">
            <div className="row "> */}
              {/* Start Single Service  */}
              {/* <div className="col-lg-6 col-md-12 mt--5 ">
                <div className="section-title">
                  <h3 className="title">Stay Updated with Our Blog</h3>
                  <p>Get the latest insights and updates from our blog.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 pt_sm--10 d-flex justify-content-lg-end ">
                <a className="rn-btn btn-solid" href="">
                  Subscribe
                </a>
                <a className="rn-btn ml-4" href="">
                  Share
                </a>
              </div> */}
              {/* End Single Service  */}
            {/* </div>
          </div>
        </div> */}
        {/*End of Stay Updated with our blog*/}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Area  */}
        <FooterTwo />
        {/* End Footer Area  */}
      </>
    );
  }
}

export default VideoPopup;
