import React, { Component } from "react";

class AboutFive extends Component {
  render() {
    let title =
        "Consultation Services for Strategic Planning and Digital Transformation",
      description =
        "At Dinosys Infotech, we offer comprehensive consultation services to help businesses with strategic planning and digital transformation. Our team of experts will guide you through every step of the process, ensuring that you achieve your goals efficiently and effectively.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h4 className="title">Strategic Planning</h4>
                        <p>
                          Develop a roadmap for success with our strategic
                          planning services.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h4 className="title">Digital Transformation</h4>
                        <p>
                          Embrace the digital age and transform your business
                          for the future.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="service-btn mt-5">
                    <a className="rn-btn " href="">
                      Learn More
                    </a>
                    <a
                      className="btn-transparent rn-btn-dark ml-4"
                      href="/service"
                    >
                      <span className="text">
                        Sign Up <FiChevronRight />
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/fifthImg.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutFive;
