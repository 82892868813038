import React, { Component } from "react";
class TeamFour extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--36 align-items-center">
              <div className="col-lg-7 col-md-12 ">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">
                      Unlock Your Investigative <br /> Potential
                    </h3>
                    <p className="description">
                      Join our crime investigation training program and gain the
                      skills to solve <br /> complex cases.
                    </p>
                    <div className="mt-4 ">
                      <a className="rn-btn btn-solid" href="https://docs.google.com/forms/d/e/1FAIpQLSdzAP4xNGOjK1BA5_huKTZIkFGFur_YWvU5VKx0MMlQKQWQ4A/viewform" target="_blank">
                        Enroll
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/eight.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TeamFour;
