import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import TeamFive from "../blocks/TeamFive";
import BlogContent from "./blog/BlogContent";
import BlindSpotDetail from "../component/BlindSpotDetail";
import TrinetraDetails from "../component/TrinetraDetails";
import EnhancingPublic from "../component/EnhancingPublic";

const ServiceDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [clickedCategory, setClickedCategory] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setClickedCategory(category);
  };

  const handleCardClick = (componentName) => {
    setSelectedComponent(componentName);
  };

  const PostList = BlogContent.slice(6, 9);
  const filteredPosts =
    selectedCategory === "All"
      ? PostList
      : PostList.filter((post) => post.course === selectedCategory);

  return (
    <React.Fragment>
      {/* Start Pagehelmet  */}
      <PageHelmet pageTitle="Service Details" />
      {/* End Pagehelmet  */}

      <Header
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      {/* Start Discover  */}
      <div className="rn-blog-area pt--120 bg_color--5 mb-dec--30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2>Discover the Latest Trends</h2>
                <p>Stay up-to-date with our informative blog articles.</p>
              </div>
            </div>
          </div>
          <div className="row pt--30">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <a
                  className="rn-btn ml-4"
                  onClick={() => handleCategoryChange("All")}
                  href="#"
                >
                  View all
                </a>
                <span
                  className={`pl--20 ${
                    selectedCategory === "Technology" ? "active" : ""
                  } ${
                    clickedCategory === "Technology"
                      ? "clicked-blue"
                      : "default-color"
                  } hover-blue`}
                  onClick={() => handleCategoryChange("Technology")}
                  style={{ cursor: "pointer" }}
                >
                  Technology
                </span>
                <span
                  className={`pl--20 ${
                    selectedCategory === "Cybersecurity" ? "active" : ""
                  } ${
                    clickedCategory === "Cybersecurity"
                      ? "clicked-blue"
                      : "default-color"
                  } hover-blue`}
                  onClick={() => handleCategoryChange("Cybersecurity")}
                  style={{ cursor: "pointer" }}
                >
                  Trending Cyber Crime
                </span>
                <span
                  className={`pl--20 ${
                    selectedCategory === "Web Development" ? "active" : ""
                  } ${
                    clickedCategory === "Web Development"
                      ? "clicked-blue clicked-bold"
                      : "default-color"
                  } hover-blue`}
                  onClick={() => handleCategoryChange("Web Development")}
                  style={{ cursor: "pointer" }}
                >
                  Case Study
                </span>
              </div>
            </div>
          </div>
          <div className="row mt--60 mt_sm--40">
            {filteredPosts.map((value, i) => (
              <div className="col-lg-4 col-md-6 col-12" key={i}>
                <div className="blog blog-style--1" onClick={() => handleCardClick(value.title)}>
                  <div className="thumbnail">
                    <a href="#">
                      <img
                        className="w-100"
                        src={`/assets/images/blog/blog-${value.images}.jpg`}
                        alt="Blog Images"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <div className="text-white">
                      <span className="font-weight-bold">{value.course}</span>
                      <span className="pl--10">5 min read</span>
                    </div>
                    <h4 className="title">
                      <a >
                        {value.title}
                      </a>
                    </h4>
                    <p className="blogtype">{value.category}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="about-area pt--120 pb--100 bg_color--5 mb-dec--30">
          {selectedComponent === "Developed Softwares " && <BlindSpotDetail />}
          {selectedComponent === "Protect Your Data " && <TrinetraDetails />}
          {selectedComponent === "Building Modern Websites" && (
            <EnhancingPublic />
          )}
        </div>
      </div>
      {/* End Discover  */}

      <div className="about-area  pb--110 bg_color--5">
        <TeamFive />
      </div>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />
    </React.Fragment>
  );
};

export default ServiceDetails;
