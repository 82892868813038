import React, { Component } from "react";
import { FiBox, FiChevronRight } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiBox />,
    title: "Software Development",
    description:
      "Customized software solutions to streamline your business processes and drive growth.",
  },
  {
    icon: <FiBox />,
    title: "Network Security",
    description:
      "Protect your valuable data and ensure the integrity of your network with our advanced security measures.",
  },
];

class ServiceFour extends Component {
  render() {
    let title = "Comprehensive IT Services for Your Business",
      description =
        "At Dinosys Infotech, we offer a wide range of IT services including software development, network security, and IT support. Our team of experts is dedicated to providing innovative solutions that meet the unique needs of your business.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h3 className="title">{title}</h3>
            </div>
          </div>
          <div className="col-lg-6 col-12 mt_md--50">
            <div className="row service-one-wrapper service">
              <p className="ml-3">{description}</p>

              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h4 className="title">{val.title}</h4>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="service-btn mt-3 ml-3">
                <a className="btn-transparent rn-btn-dark " href="/contact">
                  <span className="text">
                    Contact Us <FiChevronRight />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceFour;
