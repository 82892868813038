import React, { Component } from "react";

class TeamFive extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12 ">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">Discover the Power of Dinosys</h3>
                    <p className="description">
                      Explore our wide range of services and solutions to
                      transform your business.
                    </p>
                    <div className="mt-4">
                      <a className="rn-btn " href="/contact">
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 order-1 order-lg-2 d-flex justify-content-end">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img
                    className="w-100"
                    src="/assets/images/blog/blog-06.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TeamFive;
