import React, { Component } from "react";
import { FiBox } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiBox />,
    title: "Crime Investigation Training Programs",
    description:
      "Our crime investigation training programs provide comprehensive knowledge and practical skills to aspiring investigators. With experienced trainers and state-of-the-art facilities, we ensure that our participants are well-equipped to handle real-world scenarios.",
    url: "/crime-investigation-training",
  },
  {
    icon: <FiBox />,
    title: "Expert Consultation Services",
    description:
      "Our expert consultants offer personalized guidance and solutions to businesses seeking advice on IT strategies, cybersecurity, and digital transformation. With their in-depth industry knowledge, they help clients make informed decisions and achieve their goals.",
    url: "/service",
  },
  {
    icon: <FiBox />,
    title: "Industry-Leading Solutions for Digital Transformation",
    description:
      "We specialize in providing industry-leading solutions that drive digital transformation for businesses of all sizes. From cloud computing to data analytics, our innovative technologies enable organizations to streamline operations, enhance efficiency, and unlock new growth opportunities.",
    url: "/products",
  },
  {
    icon: <FiBox />,
    title: "Stay Ahead of Cyber Threats with Our Security Solutions",
    description:
      "With the increasing sophistication of cyber threats, our security solutions offer robust protection for your digital assets. Our team of experts implements cutting-edge measures to safeguard your networks, systems, and sensitive data from potential breaches.",
    url: "/blog-post",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Empowering Businesses with Cutting-Edge IT Solutions",
      description =
        "At Dinosys Infotech, we offer a comprehensive range of IT services, including web development, software solutions, and network security. Our team of experts is dedicated to delivering innovative and reliable solutions to help businesses thrive in the digital landscape. With our cutting-edge technologies and strategic approach, we empower businesses to stay ahead of the competition.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h3 className="title">{title}</h3>
              <p>{description}</p>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a href={val.url}>
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h4 className="title">{val.title}</h4>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceTwo;
