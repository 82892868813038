import React, { Component } from "react";
import { FiBox, FiChevronRight } from "react-icons/fi";

class AboutThree extends Component {
  render() {
    let title = "Our History, Values, and Goals",
      description =
        "At Dinosys Infotech, we have a rich history rooted in providing top-notch IT services. Our values drive us to deliver exceptional solutions, while our goals focus on continuous growth and client satisfaction.",
      icon = <FiBox />;
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <div className="service service__style--2">
                      <span className="icon  ">{icon}</span>
                      <h3 className="title">{title}</h3>
                      <div className="content">
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="service-btn  ml-4 mt-3">
                    <a className="rn-btn " href="">
                      Learn More
                    </a>
                    <a className="btn-transparent rn-btn-dark ml-4" href="">
                      <span className="text">
                        Sign Up <FiChevronRight />
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/thirdImg.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutThree;
