import React, { Component } from "react";

const ServiceList = [
  {
    icon: "50%",
    description:
      "Increase in successful crime investigations with our training",
  },
  {
    icon: "50%",
    title: "Network Security",
    description:
      "Growth in crime investigation success rate with our consultation",
  },
];

class AboutEight extends Component {
  render() {
    let title = "Discover Our Impressive Company Achievements and Milestones",
      description =
        "With years of experience, we have successfully trained and consulted on numerous crime investigations.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h3 className="title">{title}</h3>
            </div>
          </div>
          <div className="col-lg-6 col-12 mt_md--50 mt--30">
            <div className="row service-one-wrapper service">
              <p className="ml-3">{description}</p>

              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 " key={i}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content ">
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutEight;
