import React from "react";

const FooterTwo = () => {
  return (
    <div
      className="footer-style-2 ptb--30 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <div className="wrapper plr--50 plr_sm--50">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className=" text-center text-sm-left mb_sm--20">
                <div className="inner text-lg-right  mt_md--20 mt_sm--20">
                    <p>© 2024 Dinosys Infotech Pvt. Ltd All Rights Reserved.</p>
                  <div className="text">
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul
                className="social-share rn-lg-size d-flex justify-content-center liststyle 
              "
              >
                <p>
                  <u>Privacy Policy</u>
                </p>
                <p className="ml--20 ">
                  <u>Terms and Conditions </u>
                </p>
                <p className="ml--20">
                  <u>Cookie Settings</u>
                </p>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
