import React, { Component } from "react";

class AboutSeven extends Component {
  render() {
    let title = "BlindSpot",
      description =
        "Blindspot is a cutting-edge tool which analyses CDR and IPDR data that empowers frontline Police Officers in their investigations, enabling them to meet and exceed Global Policing Standards. This powerful solution enhances their capabilities and supports their efforts to ensure thorough and effective case investigations.";

    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container ">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/blindspot.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutSeven;
