import React, { Component } from "react";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from "react-scroll-up";
import { FiBox, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import AboutThree from "../component/HomeLayout/AboutThree";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import FooterTwo from "../component/footer/FooterTwo";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <PageHelmet pageTitle="About" /> */}

        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--100 pb--40 bg_color--5 ">
          <div className="container">
            <div className="">
              {/* Start Single Service  */}
              <div className=" ">
                <div className="section-title text-center">
                  <h2 className="title">Welcome to Dinosys</h2>
                  <p>
                    We are a leading IT company providing training and
                    consultation services.
                  </p>
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5">
          <AboutThree />
        </div>
        {/* End About Area */}

        {/* Start Featured Service Area  */}
        <div className="about-area pt--120 bg_color--5">
          {" "}
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="  mt--5">
                <div className="section-title">
                  <h3 className="title">Our Team</h3>
                  <p>
                    Meet the leadership team and key personnel at Dinosys
                    Infotech Pvt. Ltd.
                  </p>
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>

        {/* Start About Area  */}
        <div className="rn-about-area ptb--60 bg_color--5">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className=" row lg-d-flex justify-content-between">
                <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                        className=""
                        src="/assets/images/about/Aparna-Lidhu.jpg"
                        alt="Blog Images"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Aparna Lidhu</h4>
                      <p className="designation">Director</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 col-sm-6 col-12 ">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                        className=""
                        src="/assets/images/about/Govind-Ray.jpg"
                        alt="Blog Images"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Govind Ray </h4>
                      <p className="designation">Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* End Featured Service Area  */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--50 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="">
                <div className="section-title">
                  <h3 className="title">We're hiring!</h3>
                  <p>
                    Join our talented team and take your career to new heights.
                  </p>
                  {/* <div className="about-button mt--20">
                    <a className="rn-btn " href="">
                      View positions
                    </a>
                  </div> */}
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-featured-service-area bg_color--5 ptb--120">
          <div className="container">
            <div className="row  ">
              <div className="col-lg-6 section-title mt--10">
                <h3 className="title">
                  Building a Legacy of Technological Excellence
                </h3>
              </div>
              <div className="col-lg-6 ">
                <VerticalTimeline layout="1-column-left">
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work "
                    contentArrowStyle={{
                      borderRight: "7px solid  #003070",
                    }}
                    iconStyle={{
                      background: "#003070",
                      color: "#fff",
                    }}
                    icon={<FiBox />}
                  >
                    <h4 className="vertical-timeline-element--work">
                      Our Beginnings
                    </h4>
                    <p className="section-title">
                      Dinosys Infotech Pvt. Ltd. was founded in 2022 with a
                      vision to revolutionize the IT industry.
                    </p>
                  </VerticalTimelineElement>

                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    iconStyle={{
                      background: "#003070",
                      color: "#fff",
                    }}
                    icon={<FiBox />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      Expanding Horizons
                    </h4>
                    <p className="section-title">
                      We expanded our services to include training and
                      consultation for investigating crimes.
                    </p>
                  </VerticalTimelineElement>

                  <VerticalTimelineElement
                    className="vertical-timeline-element--education back"
                    iconStyle={{
                      background: "#003070",
                      color: "#fff",
                    }}
                    icon={<FiBox />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      Global Recognition
                    </h4>
                    <p className="section-title">
                      Our expertise gained global recognition, leading to
                      partnerships with LEA’s.
                    </p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    iconStyle={{
                      background: "#003070",
                      color: "#fff",
                    }}
                    icon={<FiBox />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      Continued Growth
                    </h4>
                    <p className="section-title">
                      We continue to grow, adapting to the ever-changing
                      landscape of technology and crime investigation.
                    </p>
                  </VerticalTimelineElement>
                </VerticalTimeline>
              </div>
            </div>
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--5">
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default About;
